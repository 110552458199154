import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
  "path": "/Frequently_Asked_Question/Mosquitto_2.0_with_Management_Dashboard/",
  "dateChanged": "2020-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Mosquitto 2.0 MQTT Broker with Management Dashboard' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='Mosquitto 2.0 MQTT Broker with Management Dashboard' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Mosquitto_2.0_with_Management_Dashboard/' locationFR='/fr/Frequently_Asked_Question/Mosquitto_2.0_with_Management_Dashboard/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://mosquitto.org/download/"
      }}>{`Mosquitto now offers a management dashboard`}</a>{` with version 2. How can I install the broker and use it with my camera?`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: The easiest way to install any of Cedalos products individually, as a bundle or the whole platform is to use the official `}<a parentName="p" {...{
        "href": "https://www.docker.com/"
      }}>{`Docker`}</a>{` based installer image `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cedalo/installer`}</code>{`, which is available at `}<a parentName="p" {...{
        "href": "https://hub.docker.com/repository/docker/cedalo/installer"
      }}>{`Docker Hub`}</a>{`.`}</p>
    <p>{`The installer will guide you through the installation process and will allow you to select which components you want to install: Eclipse Mosquitto, Eclipse Streamsheets, the Management Center or combinations of it.`}</p>
    <p>{`The following sections describe how to do the Docker based installation on `}<a parentName="p" {...{
        "href": "https://docs.cedalo.com/latest/docs/installation/#linux"
      }}>{`Linux`}</a>{` - but the installation on `}<a parentName="p" {...{
        "href": "https://docs.cedalo.com/latest/docs/installation/#windows"
      }}>{`Windows`}</a>{` and `}<a parentName="p" {...{
        "href": "https://docs.cedalo.com/latest/docs/installation/#macos"
      }}>{`macOS`}</a>{` ist mostly identical.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#install-docker"
        }}>{`Install Docker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#use-the-installer"
        }}>{`Use the installer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#start-mosquitto"
        }}>{`Start Mosquitto`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#open-the-applications"
        }}>{`Open the applications`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mosquitto-dashboard"
        }}>{`Mosquitto Dashboard`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#debugging"
            }}>{`Debugging`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-a-camera"
            }}>{`Adding a Camera`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "install-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#install-docker",
        "aria-label": "install docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install Docker`}</h2>
    <p>{`If you have not yet, install Docker for your Linux platform (see `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/#server"
      }}>{`installation instructions here`}</a>{`). For example, Docker is available for `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/centos/"
      }}>{`Centos`}</a>{`, `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/debian/"
      }}>{`Debian`}</a>{`, `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/fedora/"
      }}>{`Fedora`}</a>{` or `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/ubuntu/"
      }}>{`Ubuntu`}</a>{`.`}</p>
    <h2 {...{
      "id": "use-the-installer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-the-installer",
        "aria-label": "use the installer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use the installer`}</h2>
    <p>{`For an installation under Linux use the installer image tagged with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2.0-linux`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -it -v ~/mosquitto:/cedalo cedalo/installer:2-linux`}</code></pre></div>
    <p>{`In the following dialog you can select (using the space bar) which components should be installed:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Select what to `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` › - Space to select. Return to submit
◉   Management Center `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` Eclipse Mosquitto
◉   Eclipse Streamsheets
◉   Eclipse Mosquitto `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2.0`}</span>{`
◯   Eclipse Mosquitto `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.6`}</span></code></pre></div>
    <p>{`When you have selected the components to install, press the return key, which will start the installation process. During the installation process the following files will be created within the installation directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~/mosquitto
├── docker-compose.yml  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Docker Compose file`}</span>{`
├── mosquitto           `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#`}</span>{`
│   ├── config          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configurations for Mosquitto`}</span>{`
│   ├── data            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Data directory for Mosquitto`}</span>{`
├── start.sh            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Start script`}</span>{`
├── stop.sh             `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Stop script`}</span>{`
├── streamsheets        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#`}</span>{`
│   ├── init.json       `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configuration for Streamsheets`}</span>{`
├── update.sh           `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Update script`}</span></code></pre></div>
    <h2 {...{
      "id": "start-mosquitto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#start-mosquitto",
        "aria-label": "start mosquitto permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Start Mosquitto`}</h2>
    <p>{`To start everything you installed, just use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`start.sh`}</code>{` file.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` ~/mosquitto
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sh`}</span>{` start.sh`}</code></pre></div>
    <h2 {...{
      "id": "open-the-applications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#open-the-applications",
        "aria-label": "open the applications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Open the applications`}</h2>
    <p>{`When everything is installed and started correctly the applications should be available under the following URLs:`}</p>
    <ul>
      <li parentName="ul">{`Eclipse Mosquitto: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`mqtt://localhost:1883`}</code></li>
      <li parentName="ul">{`Eclipse Streamsheets Web UI: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://localhost:8081`}</code></li>
      <li parentName="ul">{`Management Center Web UI: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://localhost:8088`}</code></li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://docs.cedalo.com/installation/community-edition/installing-on-linux/index.html"
        }}>{`Eclipse Streamsheets`}</a>{` default login `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`admin/1234`}</code></p>
    </blockquote>
    <h2 {...{
      "id": "mosquitto-dashboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mosquitto-dashboard",
        "aria-label": "mosquitto dashboard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mosquitto Dashboard`}</h2>
    <p>{`Open the following URL inside your Webbrowser `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8088`}</code>{` and you will be greeted by the broker dashboard. Switch to the client page and click on the `}<strong parentName="p">{`Plus`}</strong>{` button in the lower right to add a new client:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/239d7f66718260981ac068eec7a8c2fa/471ef/Mosquitto_2_Dashboard_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABoklEQVQoz32QzW7TUBCF/UqkiR3buY59f+3acULTgoQKUhdILeVngYSE6IYVG96DB0rVAhLP8qF7jUNVVV0cnZk5dz6PHD1/u+PVxxtef75le7Fj+2bH8eX1ozq5vOboYsf7r7/58v0P51c/Ob/6xdmnW6Kmf0lWdGSiJRXeV0xmhoPYBp/Eg3z/X8MsTi3pwpFkLtSzuSV65jR5MiXP5xQiQ8qCLI1J5zPyLAm11zyZ7uUzP0viKXF8EDxJpsSzCdFZUdKIgq5fY42lrhtWqx7natbrDV3X0bYdSimU0kipQuacQUqJ1jrMtTZUlSQ6ajsKITDWslwuw+Jmswnu+7Isw+II1FqhTcv65APWNigpMcaE3L+NjHMIIWjqOixKpWiaJjwK/T15gDY1h6tTlDJ3PuYlieq6DsC+7/+dP1zonKOqqv2CzwYpjFuxPn6HMTbMrDVY42gOnxK1bUue5+HcEeB9rB+Uz0ux70spEVpRFAsif4kHjuFd0Ai+ryFXAexhriw5LSqU3hBZa/fAR6966H9WFQuleJGn/Hii6bff+AsAYnPVM7hnJQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/239d7f66718260981ac068eec7a8c2fa/e4706/Mosquitto_2_Dashboard_01.avif 230w", "/en/static/239d7f66718260981ac068eec7a8c2fa/d1af7/Mosquitto_2_Dashboard_01.avif 460w", "/en/static/239d7f66718260981ac068eec7a8c2fa/7f308/Mosquitto_2_Dashboard_01.avif 920w", "/en/static/239d7f66718260981ac068eec7a8c2fa/7e7d6/Mosquitto_2_Dashboard_01.avif 1185w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/239d7f66718260981ac068eec7a8c2fa/a0b58/Mosquitto_2_Dashboard_01.webp 230w", "/en/static/239d7f66718260981ac068eec7a8c2fa/bc10c/Mosquitto_2_Dashboard_01.webp 460w", "/en/static/239d7f66718260981ac068eec7a8c2fa/966d8/Mosquitto_2_Dashboard_01.webp 920w", "/en/static/239d7f66718260981ac068eec7a8c2fa/81cb7/Mosquitto_2_Dashboard_01.webp 1185w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/239d7f66718260981ac068eec7a8c2fa/81c8e/Mosquitto_2_Dashboard_01.png 230w", "/en/static/239d7f66718260981ac068eec7a8c2fa/08a84/Mosquitto_2_Dashboard_01.png 460w", "/en/static/239d7f66718260981ac068eec7a8c2fa/c0255/Mosquitto_2_Dashboard_01.png 920w", "/en/static/239d7f66718260981ac068eec7a8c2fa/471ef/Mosquitto_2_Dashboard_01.png 1185w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/239d7f66718260981ac068eec7a8c2fa/c0255/Mosquitto_2_Dashboard_01.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "debugging",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#debugging",
        "aria-label": "debugging permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Debugging`}</h3>
    <p>{`I want to start by adding a debugging tool - e.g. MQTT.fx, MQTT Explorer, etc - that helps me see what traffic is being handled by my broker. I will use the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT.fx Tool`}</a>{`. To be able to connect to my Mosquitto Broker I first have to create a user for the new client:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/95e27/Mosquitto_2_Dashboard_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/ElEQVQoz5WPzUrEMBSF80a2EH/S5CYdk/TvttO0KnRhRawzuHAjWPAB5gFcOC9kVSr4UFLdOlo/Pu7mcOBccnrz0ty+Xt2P9Xpwq8FdD9XqZ8vuuevHfvOxfni/uHvr+pGU7oQxoPuMHrDDI8658jy6yz2Pej71/el6PiWIWRCwLEusNRKEUlJKsVMQ8KWSEASMIKIQomma5bLQWhtj5xBFURiGpK5rIURRFHEcW2v1PIwxUkqSpqngoqoq5xwizi8rpYhzjnPuXJkkyWKx+A7+xFo7lfN8+hlzV5Y1Ilpj/zE7zxFAnl1uz7vN9umxbVsA0Fof/4rWGgA+AYBQm9Fu4Et1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8bb4065b9a018c1add9f17d8cd8c8708/e4706/Mosquitto_2_Dashboard_02.avif 230w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/d1af7/Mosquitto_2_Dashboard_02.avif 460w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/7f308/Mosquitto_2_Dashboard_02.avif 920w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/20787/Mosquitto_2_Dashboard_02.avif 1199w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8bb4065b9a018c1add9f17d8cd8c8708/a0b58/Mosquitto_2_Dashboard_02.webp 230w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/bc10c/Mosquitto_2_Dashboard_02.webp 460w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/966d8/Mosquitto_2_Dashboard_02.webp 920w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/7a7d4/Mosquitto_2_Dashboard_02.webp 1199w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8bb4065b9a018c1add9f17d8cd8c8708/81c8e/Mosquitto_2_Dashboard_02.png 230w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/08a84/Mosquitto_2_Dashboard_02.png 460w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/c0255/Mosquitto_2_Dashboard_02.png 920w", "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/95e27/Mosquitto_2_Dashboard_02.png 1199w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8bb4065b9a018c1add9f17d8cd8c8708/c0255/Mosquitto_2_Dashboard_02.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose your Client ID and Login freely and click on save to add the new client. Now we need to give our new client permission to subscribe/publish to MQTT topics - this can be done inside the `}<strong parentName="p">{`Roles`}</strong>{` menu. Click on the `}<strong parentName="p">{`Plus`}</strong>{` button to add a new role:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a1fc74eeeb32b769ad845756745adf1/73b94/Mosquitto_2_Dashboard_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABjElEQVQoz42N308TQRDH909ReDiQ7uxsBXvX7v3Y3R53Xveu1ws2eCrtSQgmBkICL0ZNjDHxxegLDyYm8ocBATT6d5heCemLhU8+DzPfmcmQpwfnrz9c7by9eLJ3Ntw/He6f/c93X/98PP57+Pn37vvLg0+/tt9cEJ288rpjP3wpVCV05QRbtaMZrxM/HKu4klHl6rEXjoUeEVesra2C/Qgdm7ftJrKlJi4jW7qR4zKvE6AWbVhALQYWg0lLhOtFURyGYRBIPwiiOB4UhdZdpfTUrJ/3+7mU6iaZ2mrZxBNiPZog6/lkRWs1g66ZTaSUxhjHcUjabudFPsgz03ucmsSYJLsNY0xZlp7nkdTprPeG/fxZL9s0WZmkm1p39VyUUnEc27ZNfNePNr5tVCfF1o9i9DMtjxmuAjRgLpRSxhgRQlBKARiwWmA4F47YROScI3LS6XQAYFJPZ/yWywcc7z9srlCLNSziui4A4B3giCscB5R9X1jM/Oct+eL6812PEVPALwv3BtGhSI7+AXCEvo/U4ix+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a1fc74eeeb32b769ad845756745adf1/e4706/Mosquitto_2_Dashboard_03.avif 230w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/d1af7/Mosquitto_2_Dashboard_03.avif 460w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/7f308/Mosquitto_2_Dashboard_03.avif 920w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/f13f3/Mosquitto_2_Dashboard_03.avif 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a1fc74eeeb32b769ad845756745adf1/a0b58/Mosquitto_2_Dashboard_03.webp 230w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/bc10c/Mosquitto_2_Dashboard_03.webp 460w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/966d8/Mosquitto_2_Dashboard_03.webp 920w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/22cc9/Mosquitto_2_Dashboard_03.webp 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a1fc74eeeb32b769ad845756745adf1/81c8e/Mosquitto_2_Dashboard_03.png 230w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/08a84/Mosquitto_2_Dashboard_03.png 460w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/c0255/Mosquitto_2_Dashboard_03.png 920w", "/en/static/4a1fc74eeeb32b769ad845756745adf1/73b94/Mosquitto_2_Dashboard_03.png 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a1fc74eeeb32b769ad845756745adf1/c0255/Mosquitto_2_Dashboard_03.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We first have to create the role - I just called it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqttfx`}</code>{` - by clicking on `}<strong parentName="p">{`Save`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a123db42a13b46209a2de0667c9d5424/69476/Mosquitto_2_Dashboard_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAv0lEQVQY05XQQQuCMBjGcb+QMMO9Oj3sZZsryGZbIRgdoq5Rh0j6BtExP3GIEFEa9bv/eeDxKKUAQIEmCaOUEkKC3xBCvDAM8zw3xiBv4TD+AhGjKPIAYG7n1tnxWEsh5ACllHghpYzjuI2NMaYoxHDZ6YljgKKw1i201pnOlFJ/xH4E01wvFzPOOWMMEUWf/nhLRuvV2a4um82urs/OOUT8sv+8gDHm7f2gqq6T8nY4nprmXpZlmqZv337q3n4AxpR8DPT8zQEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a123db42a13b46209a2de0667c9d5424/e4706/Mosquitto_2_Dashboard_04.avif 230w", "/en/static/a123db42a13b46209a2de0667c9d5424/d1af7/Mosquitto_2_Dashboard_04.avif 460w", "/en/static/a123db42a13b46209a2de0667c9d5424/7f308/Mosquitto_2_Dashboard_04.avif 920w", "/en/static/a123db42a13b46209a2de0667c9d5424/72ef3/Mosquitto_2_Dashboard_04.avif 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a123db42a13b46209a2de0667c9d5424/a0b58/Mosquitto_2_Dashboard_04.webp 230w", "/en/static/a123db42a13b46209a2de0667c9d5424/bc10c/Mosquitto_2_Dashboard_04.webp 460w", "/en/static/a123db42a13b46209a2de0667c9d5424/966d8/Mosquitto_2_Dashboard_04.webp 920w", "/en/static/a123db42a13b46209a2de0667c9d5424/dafe9/Mosquitto_2_Dashboard_04.webp 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a123db42a13b46209a2de0667c9d5424/81c8e/Mosquitto_2_Dashboard_04.png 230w", "/en/static/a123db42a13b46209a2de0667c9d5424/08a84/Mosquitto_2_Dashboard_04.png 460w", "/en/static/a123db42a13b46209a2de0667c9d5424/c0255/Mosquitto_2_Dashboard_04.png 920w", "/en/static/a123db42a13b46209a2de0667c9d5424/69476/Mosquitto_2_Dashboard_04.png 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a123db42a13b46209a2de0667c9d5424/c0255/Mosquitto_2_Dashboard_04.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This will bring you back to the role list - click on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqttfx`}</code>{` role to edit it again. Now you will have another tab called `}<strong parentName="p">{`ACLS`}</strong>{`. Here we have to tell Mosquitto what MQTT topics the client with this role is allowed to listen to. For debugging I choose the wildcard `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#`}</code>{` - which means `}<strong parentName="p">{`all of them`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fdf12907387415b0652495be0a3eeb61/416ee/Mosquitto_2_Dashboard_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABCklEQVQoz43R/4rDIAwH8D5RYQeFstloUk2uWrVbufd/lWPNTe4H7Pb9K0I+GmI3DIOIBObJmLfTqe/708vpxnFMKeWcjTFgYd93RAQA+zTOucvl0p3P55TSuq5Ec+DldvtgjrMP8xFEJKJWI6LW3vtpmjoAEBFnLdGMSN57kXc1RBRj9N4TESKKCDO36+7YWrvExTnXOpZlaTjnzCEQkXNu3/dSsnYSkTGmgwlqKbWWEIJORY9ohY9jOKIXxRgR8f7y9XrdahURRPRHvvA8a3cbSkR+YAC71a2UElNS3B7XxTTMzCL8C0Pdai5Z9/kP5j9422r9NvaLmIjuOOe8rqti/cbnGBH1Rz4BiSOqvdTQRxwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fdf12907387415b0652495be0a3eeb61/e4706/Mosquitto_2_Dashboard_05.avif 230w", "/en/static/fdf12907387415b0652495be0a3eeb61/d1af7/Mosquitto_2_Dashboard_05.avif 460w", "/en/static/fdf12907387415b0652495be0a3eeb61/7f308/Mosquitto_2_Dashboard_05.avif 920w", "/en/static/fdf12907387415b0652495be0a3eeb61/960ed/Mosquitto_2_Dashboard_05.avif 930w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fdf12907387415b0652495be0a3eeb61/a0b58/Mosquitto_2_Dashboard_05.webp 230w", "/en/static/fdf12907387415b0652495be0a3eeb61/bc10c/Mosquitto_2_Dashboard_05.webp 460w", "/en/static/fdf12907387415b0652495be0a3eeb61/966d8/Mosquitto_2_Dashboard_05.webp 920w", "/en/static/fdf12907387415b0652495be0a3eeb61/6eb96/Mosquitto_2_Dashboard_05.webp 930w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fdf12907387415b0652495be0a3eeb61/81c8e/Mosquitto_2_Dashboard_05.png 230w", "/en/static/fdf12907387415b0652495be0a3eeb61/08a84/Mosquitto_2_Dashboard_05.png 460w", "/en/static/fdf12907387415b0652495be0a3eeb61/c0255/Mosquitto_2_Dashboard_05.png 920w", "/en/static/fdf12907387415b0652495be0a3eeb61/416ee/Mosquitto_2_Dashboard_05.png 930w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fdf12907387415b0652495be0a3eeb61/c0255/Mosquitto_2_Dashboard_05.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now return to your list of clients and assign the role `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqttfx`}</code>{` to your MQTT.fx client:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/896a05953cf132d830c0a09aaae9e653/a9965/Mosquitto_2_Dashboard_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAtElEQVQY01XQS47CMAwG4Bwt8UNxHHCTlJQilR1rYA4wdx+VCDR88sb+LS/sACDGGEIAAEQ0s0/7QQjEqfQH0R4R0T4kcgCQUoovImJmIjLa9I+IWrmq5pSS6r6vmpyZ9Zdt29b1vCxLezu99d7nea61lXoa1VrfrjfHzGY2zmfVUormLN9GWktlJAIkwBiltu5CCGZHAPDeI+I0TczsvR8vGIBoRvxdL8/jdD/Yjx4acMztD24BV2dVec83AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/896a05953cf132d830c0a09aaae9e653/e4706/Mosquitto_2_Dashboard_06.avif 230w", "/en/static/896a05953cf132d830c0a09aaae9e653/d1af7/Mosquitto_2_Dashboard_06.avif 460w", "/en/static/896a05953cf132d830c0a09aaae9e653/7f308/Mosquitto_2_Dashboard_06.avif 920w", "/en/static/896a05953cf132d830c0a09aaae9e653/39686/Mosquitto_2_Dashboard_06.avif 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/896a05953cf132d830c0a09aaae9e653/a0b58/Mosquitto_2_Dashboard_06.webp 230w", "/en/static/896a05953cf132d830c0a09aaae9e653/bc10c/Mosquitto_2_Dashboard_06.webp 460w", "/en/static/896a05953cf132d830c0a09aaae9e653/966d8/Mosquitto_2_Dashboard_06.webp 920w", "/en/static/896a05953cf132d830c0a09aaae9e653/37778/Mosquitto_2_Dashboard_06.webp 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/896a05953cf132d830c0a09aaae9e653/81c8e/Mosquitto_2_Dashboard_06.png 230w", "/en/static/896a05953cf132d830c0a09aaae9e653/08a84/Mosquitto_2_Dashboard_06.png 460w", "/en/static/896a05953cf132d830c0a09aaae9e653/c0255/Mosquitto_2_Dashboard_06.png 920w", "/en/static/896a05953cf132d830c0a09aaae9e653/a9965/Mosquitto_2_Dashboard_06.png 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/896a05953cf132d830c0a09aaae9e653/c0255/Mosquitto_2_Dashboard_06.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we have to configure `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT.fx`}</a>{` with the same information:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d226a2e3b3c21f41ecf450d83e383fce/51ed8/Mosquitto_2_Dashboard_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABfElEQVQY003O326aUBzAcV5lWecqoJxzfsejRwELRwLY+Kf0RBFZNufV0ixtoj5A32e96hIvFDC6B1tsS9PP/Tf5Kn+fn5/+PG232/1+v9vt9lmW50WWF3le5MWhOJwdj8fT6fSvdDgUeZFleaZwzjVN+1JSq9Vq5fPlxae69tXijfFomCTzeDaLotvxi+Fw+D1dxjcpZ21FCJcxput67UynjLuTX2L2W0zvevMH0/F5q9lstjAmhlFHCGGMu6bbbliUUMW2bYSQ/kYjlHX6s/b1N/d2GSR3YxlPpxMpZRRFUsrBYAAACCOgBCgoQIiqqmVcIxijum5oFdArrtVaLn5sNps0TaWUcRyPRiMhhGmahBAAUGijqaqqViIAttmxxwvx89FNVp7fF64b9vtB4AshHMexLItz/hZbV+K8+x4TwliDtbstJzRF2Ot5YRgEQeD7PmPMMAyM8Wt5jl+3P8YAQLAB2LjqWuk8ub9/WK1W6/Xa8zyEEKUUSv8BmIqRRsMuWTMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d226a2e3b3c21f41ecf450d83e383fce/e4706/Mosquitto_2_Dashboard_07.avif 230w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/d1af7/Mosquitto_2_Dashboard_07.avif 460w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/7f308/Mosquitto_2_Dashboard_07.avif 920w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/14e47/Mosquitto_2_Dashboard_07.avif 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d226a2e3b3c21f41ecf450d83e383fce/a0b58/Mosquitto_2_Dashboard_07.webp 230w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/bc10c/Mosquitto_2_Dashboard_07.webp 460w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/966d8/Mosquitto_2_Dashboard_07.webp 920w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/11b7f/Mosquitto_2_Dashboard_07.webp 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d226a2e3b3c21f41ecf450d83e383fce/81c8e/Mosquitto_2_Dashboard_07.png 230w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/08a84/Mosquitto_2_Dashboard_07.png 460w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/c0255/Mosquitto_2_Dashboard_07.png 920w", "/en/static/d226a2e3b3c21f41ecf450d83e383fce/51ed8/Mosquitto_2_Dashboard_07.png 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d226a2e3b3c21f41ecf450d83e383fce/c0255/Mosquitto_2_Dashboard_07.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Try to connect to Mosquitto and publish a message to see if everything was set up correctly:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "901px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/db95d96e86d3e685e87a06140a1434a5/0955e/Mosquitto_2_Dashboard_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.304347826086953%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/ElEQVQY01XHPU+DQADG8ftEyIu03J1Q744DOjRx8gXaxE6NTrh1AQ6QDsYFzuBgYimJ/ZxG0hj6y394HvD9tT/0/c/x2Pf9YWQ43Wmf67puPwCG7VsQTqcTY0TTDR27l7PAca4JIaqqXowoimKaE8c1gBu++I9b3/MoY5xzxtg8CAilV7ZtWZaFEcIYIYQghNCCIwhBMHPnd8t1FEXLf6tV+HDPF7d8vd2Y2NE1VddsvvBuQpcRQqnneZRSiG3w/LQpC1FVu3NVXr5mu/e3JBNJkqRpKvIsL0V2UhRFHMcgSdO2/azrujkn/6prKeXHQEopGzlomqZtWyHEL1u+k1tH6APqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db95d96e86d3e685e87a06140a1434a5/e4706/Mosquitto_2_Dashboard_08.avif 230w", "/en/static/db95d96e86d3e685e87a06140a1434a5/d1af7/Mosquitto_2_Dashboard_08.avif 460w", "/en/static/db95d96e86d3e685e87a06140a1434a5/81e7a/Mosquitto_2_Dashboard_08.avif 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/db95d96e86d3e685e87a06140a1434a5/a0b58/Mosquitto_2_Dashboard_08.webp 230w", "/en/static/db95d96e86d3e685e87a06140a1434a5/bc10c/Mosquitto_2_Dashboard_08.webp 460w", "/en/static/db95d96e86d3e685e87a06140a1434a5/2b666/Mosquitto_2_Dashboard_08.webp 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db95d96e86d3e685e87a06140a1434a5/81c8e/Mosquitto_2_Dashboard_08.png 230w", "/en/static/db95d96e86d3e685e87a06140a1434a5/08a84/Mosquitto_2_Dashboard_08.png 460w", "/en/static/db95d96e86d3e685e87a06140a1434a5/0955e/Mosquitto_2_Dashboard_08.png 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/db95d96e86d3e685e87a06140a1434a5/0955e/Mosquitto_2_Dashboard_08.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the broker dashboard switch to the `}<strong parentName="p">{`Topic Tree`}</strong>{` and check if your test was received:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/db502bf36c32d9d669663413c2f8fe82/72372/Mosquitto_2_Dashboard_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABKElEQVQoz22Ry07DMBBF+0csqBOpfsS+M7bzcNMoqgoIIbFAYs8vsED8BZ+K7AIlbY+8GcvH155ZKaW01k3TSCmFEJWobypxW4u6qoQQ6/U6b1a5qJYIIVZENE0TAc45InbBvZntg+msJ6M1M6eUAMQ24hcqOOdW1tphGGKMgLOWukF/BXqpexWgpWLm3W4EME1T27bB+xjjSXbOxtiGEAAwsAE+5/p1E6XPMmV5ByANhb5PKTHzKdmH2PZ3FObYP6anj/f7/fOGtSetFBF1XecA9owl1tosHz/yt4xjCzCRUoqAeZ65QEt+ks+uJDhyuSuqJOd2EuGC/8nnZFlK7/3hcADgS/K5XCZ0BWbWWoNoLA0bt2OZCBbPNsY017DWysKxvDxkjPkGs42cUZ+91TMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db502bf36c32d9d669663413c2f8fe82/e4706/Mosquitto_2_Dashboard_09.avif 230w", "/en/static/db502bf36c32d9d669663413c2f8fe82/d1af7/Mosquitto_2_Dashboard_09.avif 460w", "/en/static/db502bf36c32d9d669663413c2f8fe82/7f308/Mosquitto_2_Dashboard_09.avif 920w", "/en/static/db502bf36c32d9d669663413c2f8fe82/433d4/Mosquitto_2_Dashboard_09.avif 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/db502bf36c32d9d669663413c2f8fe82/a0b58/Mosquitto_2_Dashboard_09.webp 230w", "/en/static/db502bf36c32d9d669663413c2f8fe82/bc10c/Mosquitto_2_Dashboard_09.webp 460w", "/en/static/db502bf36c32d9d669663413c2f8fe82/966d8/Mosquitto_2_Dashboard_09.webp 920w", "/en/static/db502bf36c32d9d669663413c2f8fe82/27033/Mosquitto_2_Dashboard_09.webp 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/db502bf36c32d9d669663413c2f8fe82/81c8e/Mosquitto_2_Dashboard_09.png 230w", "/en/static/db502bf36c32d9d669663413c2f8fe82/08a84/Mosquitto_2_Dashboard_09.png 460w", "/en/static/db502bf36c32d9d669663413c2f8fe82/c0255/Mosquitto_2_Dashboard_09.png 920w", "/en/static/db502bf36c32d9d669663413c2f8fe82/72372/Mosquitto_2_Dashboard_09.png 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/db502bf36c32d9d669663413c2f8fe82/c0255/Mosquitto_2_Dashboard_09.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "adding-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-a-camera",
        "aria-label": "adding a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding a Camera`}</h3>
    <p>{`Start by checking your MQTT configuration inside your camera. We want to add our new Mosquitto broker on port 1883. We also need to add a `}<strong parentName="p">{`Client ID`}</strong>{` and `}<strong parentName="p">{`Authentication`}</strong>{` that we will have to use later to set up the new client on our broker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d94b09394120c869ec9c89c96fa0aaaf/416ee/Mosquitto_2_Dashboard_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACO0lEQVQoz22SS2/TQBRG80egAurGYzvpgkedxqFK49jxe+bOxPH4hdUHFKUUVYjHoiAVVaIUCRVVsGDLjh1IdMueX4WaIW0VenTl3dF3/d2pNZtNTdNUVdU0TVEUhJAkLaxUB+b4S2fzuLP56cqxxyfO9kmt0WgIWVXVM1mW67KS7H0rDk/j/R/D/Z/x2ytm7ejXxtFpTZ2CJtTr9cXFRUbCqkh5DBB5ELrk0kDoQeBgOoqStdrMzrIsa5rGGCvLMsvyPM/jOAagABQTQgCiCIdhEFJO+PqFLJIVRZmbm/N9P89zznmSJBMZoigyTbPb7RqG0W63l1u6bfX/ySJWJCOEAKAsyzzPkySZ5AHG2LZtYYrvYODMyuKfh8NhURRZljHGAIBSGkVRr9czDEPX9VarpS8t2ZY1W5gs1xuNJqU0TdPRaARTCCGO4/SnmH3LD6KLU50X1tBUxmhVVWmaip1nIQRGBWQPL5JFuIIWbi3ciTCvqpIxNpUpxtjsrd7vGEZ7eVm/p9+9bfd7NXGbabKiKtK1Gx3Hy1IeMzakUzAmq7bXtTzLIy5kNuYBiWsIISGLS6mKdP1m2wvyskwFnCdAaUzJ6y36bhzujjfS97/XT/6w7cMz+VJhijKRXT/LsyThnFJKCMGEcoa/7trfX1kH22Tw5Ag/+xg82KmhWeR5CXl+yBgV7Uy2Bhgm7qNDZ+czK158sMxj133qef/LSJLmBwN78gzPCUJMYO05bL0ZpY9f2u6eG667/l9z3fuv6bx9jAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94b09394120c869ec9c89c96fa0aaaf/e4706/Mosquitto_2_Dashboard_10.avif 230w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/d1af7/Mosquitto_2_Dashboard_10.avif 460w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/7f308/Mosquitto_2_Dashboard_10.avif 920w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/960ed/Mosquitto_2_Dashboard_10.avif 930w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d94b09394120c869ec9c89c96fa0aaaf/a0b58/Mosquitto_2_Dashboard_10.webp 230w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/bc10c/Mosquitto_2_Dashboard_10.webp 460w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/966d8/Mosquitto_2_Dashboard_10.webp 920w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/6eb96/Mosquitto_2_Dashboard_10.webp 930w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94b09394120c869ec9c89c96fa0aaaf/81c8e/Mosquitto_2_Dashboard_10.png 230w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/08a84/Mosquitto_2_Dashboard_10.png 460w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/c0255/Mosquitto_2_Dashboard_10.png 920w", "/en/static/d94b09394120c869ec9c89c96fa0aaaf/416ee/Mosquitto_2_Dashboard_10.png 930w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d94b09394120c869ec9c89c96fa0aaaf/c0255/Mosquitto_2_Dashboard_10.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On our broker dashboard click to add a new client and add the authentication and client ID you set inside your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c0244550cee325a7722599c9ef28bb28/09e48/Mosquitto_2_Dashboard_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAABLElEQVQoz6XR0UrDMBgF4DyMeKHrGJvD2i3JnzTVSme3rl2Z4I0TBBGmtBNExIHsFXw7d7EnObIIu167i48E/nBySNj0ZY3Hzw2elhvk8zUm81/kO+vKWDj6gBmUuBwuIMMC4qoAXZd2zy9fK2OSPLRaJ+i0HXTPHHQ7DryLNtzzFhqnR2g6x2g29sduBIH3+oiHIxgTQEq1Q0qDSP+ve2K3Xg+BkLifzTCMY2itYYypjRkiCCGQJAnCMLS079vgOpjS2gbmeW7Dtrf4vl8bIyJwzpFlGaIoOijMBm5rci6QphMkyfjgloxIgfc9ZOkAWRZj29h1XfsetQI596D8EaYPPxhP37FafeNruUQQBFBK2UOVPoVIQFCE9G6FKHnG26JEUZR2KKW0jav4AyMvv/2x3LjAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0244550cee325a7722599c9ef28bb28/e4706/Mosquitto_2_Dashboard_11.avif 230w", "/en/static/c0244550cee325a7722599c9ef28bb28/d1af7/Mosquitto_2_Dashboard_11.avif 460w", "/en/static/c0244550cee325a7722599c9ef28bb28/7f308/Mosquitto_2_Dashboard_11.avif 920w", "/en/static/c0244550cee325a7722599c9ef28bb28/5578a/Mosquitto_2_Dashboard_11.avif 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c0244550cee325a7722599c9ef28bb28/a0b58/Mosquitto_2_Dashboard_11.webp 230w", "/en/static/c0244550cee325a7722599c9ef28bb28/bc10c/Mosquitto_2_Dashboard_11.webp 460w", "/en/static/c0244550cee325a7722599c9ef28bb28/966d8/Mosquitto_2_Dashboard_11.webp 920w", "/en/static/c0244550cee325a7722599c9ef28bb28/7efa9/Mosquitto_2_Dashboard_11.webp 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0244550cee325a7722599c9ef28bb28/81c8e/Mosquitto_2_Dashboard_11.png 230w", "/en/static/c0244550cee325a7722599c9ef28bb28/08a84/Mosquitto_2_Dashboard_11.png 460w", "/en/static/c0244550cee325a7722599c9ef28bb28/c0255/Mosquitto_2_Dashboard_11.png 920w", "/en/static/c0244550cee325a7722599c9ef28bb28/09e48/Mosquitto_2_Dashboard_11.png 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c0244550cee325a7722599c9ef28bb28/c0255/Mosquitto_2_Dashboard_11.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking on `}<strong parentName="p">{`Save`}</strong>{` will bring you back to the client list. Now switch to the `}<strong parentName="p">{`Roles`}</strong>{` tab and click on the `}<strong parentName="p">{`Plus`}</strong>{` button to add a new role for your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "901px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f2940305f3b0100441ba4f1d67a467cb/0955e/Mosquitto_2_Dashboard_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3klEQVQoz43QsW6DMBDGcb9RkWpMOJ9RcUxsio0JhihInSOyMHVqhw7NmL5BH7bqBlJK89tu+OuTjnjvEZFSymLGGAOAKIoe7kO6rkPEmDHkCMA552xVPEOqqgKALMuGYXguyzzPt39TSs1P4psmBRBChBBKY6SU21Vyhry2YZ9sMsR9+8vof/pFfDZlk2yeUFhbW+u03hVFcW9snXtEpBnvgzv0zU7rNE1XxhexPx5PlL4L34/f7fBxuXxer1/OOSmlumXxMFPXL4yNiTKHN21P53GcpklKyTnHW8TMD82GjFe6Ql41AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2940305f3b0100441ba4f1d67a467cb/e4706/Mosquitto_2_Dashboard_12.avif 230w", "/en/static/f2940305f3b0100441ba4f1d67a467cb/d1af7/Mosquitto_2_Dashboard_12.avif 460w", "/en/static/f2940305f3b0100441ba4f1d67a467cb/81e7a/Mosquitto_2_Dashboard_12.avif 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f2940305f3b0100441ba4f1d67a467cb/a0b58/Mosquitto_2_Dashboard_12.webp 230w", "/en/static/f2940305f3b0100441ba4f1d67a467cb/bc10c/Mosquitto_2_Dashboard_12.webp 460w", "/en/static/f2940305f3b0100441ba4f1d67a467cb/2b666/Mosquitto_2_Dashboard_12.webp 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2940305f3b0100441ba4f1d67a467cb/81c8e/Mosquitto_2_Dashboard_12.png 230w", "/en/static/f2940305f3b0100441ba4f1d67a467cb/08a84/Mosquitto_2_Dashboard_12.png 460w", "/en/static/f2940305f3b0100441ba4f1d67a467cb/0955e/Mosquitto_2_Dashboard_12.png 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f2940305f3b0100441ba4f1d67a467cb/0955e/Mosquitto_2_Dashboard_12.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Again we first have to create the role before we can start editing it's permissions. So click save and re-enter it to edit the ACLS settings. Here I want to make sure that this camera only receives topics that are meant for it. In my MQTT configuration (inside the camera webUI) I configured the `}<strong parentName="p">{`MQTT Prefix`}</strong>{` to be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras`}</code>{` and the `}<strong parentName="p">{`MQTT ID`}</strong>{` to be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`116`}</code>{`. So I will set the publish and subscribe pattern to be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/116/#`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df31160d173cd5f100b4f09ee4d66a79/fbf76/Mosquitto_2_Dashboard_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABDElEQVQoz2WRSXIDIQxF+0p2L1yxzSAJSdBM7V3OkPsfIGVIOnHyVr8oXn0hllrK5XIRYWPM6XRa1/X8zZHXwfkfSyrl7XotpYgIUeh9B0QYeO+PcOQD59zyXqu731QVEYlCTBuzAAAiqioRAQAzxxjxFe/98iGabvdcq4gAeBHO20ZEiJhSmrLGmPOGCGHwI/dts3ez73trDRGZubYmImMQmveYOYQAADKYcz3l2poxpvdeSvmSa2VmRJwlv52/cs75kL33zNxem6fDzPPxL7KqGmtLKTHGEIKIzEBEc2FExAMiSinNoeYuFuecMeaxP3rvKSVV5RDmx8ho894/D58OtNYAwFrrnLPWfgJxdIytPk74QAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df31160d173cd5f100b4f09ee4d66a79/e4706/Mosquitto_2_Dashboard_13.avif 230w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/d1af7/Mosquitto_2_Dashboard_13.avif 460w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/7f308/Mosquitto_2_Dashboard_13.avif 920w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/ceb0a/Mosquitto_2_Dashboard_13.avif 1252w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df31160d173cd5f100b4f09ee4d66a79/a0b58/Mosquitto_2_Dashboard_13.webp 230w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/bc10c/Mosquitto_2_Dashboard_13.webp 460w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/966d8/Mosquitto_2_Dashboard_13.webp 920w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/5733b/Mosquitto_2_Dashboard_13.webp 1252w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df31160d173cd5f100b4f09ee4d66a79/81c8e/Mosquitto_2_Dashboard_13.png 230w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/08a84/Mosquitto_2_Dashboard_13.png 460w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/c0255/Mosquitto_2_Dashboard_13.png 920w", "/en/static/df31160d173cd5f100b4f09ee4d66a79/fbf76/Mosquitto_2_Dashboard_13.png 1252w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df31160d173cd5f100b4f09ee4d66a79/c0255/Mosquitto_2_Dashboard_13.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the `}<strong parentName="p">{`Client List`}</strong>{` you have to assign the new role to allow your camera to publish and subscribe to MQTT topics:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/826f256831388246f57a7f5de45b1ee5/c9c44/Mosquitto_2_Dashboard_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABI0lEQVQY043P3U7DMAwF4DxTkYBuXRs7/3GSJl1WjTHBBUIIBO9/i7ZqG5d88pWlIx8zF8L9w2PbtutuvVq1TdPc/U/T3LE3qexmQyEYY6xziBhjlFKlmJxzRmvOOQAgorMW4QYR2Isn7DbOe6WUtRYRyXsA4JzjhRACEWL+8GGPyLXWiIKoMEfEAXIew4JCyWU8I6L4hw+jD9GHcBoK03ZmzjkhZa21nOWca627eT4cDjnn6WI7TZ+lfpf5q+x+yu44lvnplcUY+75fCiOi1jrnfC6G8kIpJYU4lrxPcdR6awwJKaRk3vuu65RSy29CyCV5/fa0EhIAfKrGp34YBgAOYI1hWuthGIy1Sikics5dW5xit8uYpveQngVya4yUKsT6CzaWg96K1uFxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/826f256831388246f57a7f5de45b1ee5/e4706/Mosquitto_2_Dashboard_14.avif 230w", "/en/static/826f256831388246f57a7f5de45b1ee5/d1af7/Mosquitto_2_Dashboard_14.avif 460w", "/en/static/826f256831388246f57a7f5de45b1ee5/7f308/Mosquitto_2_Dashboard_14.avif 920w", "/en/static/826f256831388246f57a7f5de45b1ee5/b3f84/Mosquitto_2_Dashboard_14.avif 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/826f256831388246f57a7f5de45b1ee5/a0b58/Mosquitto_2_Dashboard_14.webp 230w", "/en/static/826f256831388246f57a7f5de45b1ee5/bc10c/Mosquitto_2_Dashboard_14.webp 460w", "/en/static/826f256831388246f57a7f5de45b1ee5/966d8/Mosquitto_2_Dashboard_14.webp 920w", "/en/static/826f256831388246f57a7f5de45b1ee5/2b169/Mosquitto_2_Dashboard_14.webp 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/826f256831388246f57a7f5de45b1ee5/81c8e/Mosquitto_2_Dashboard_14.png 230w", "/en/static/826f256831388246f57a7f5de45b1ee5/08a84/Mosquitto_2_Dashboard_14.png 460w", "/en/static/826f256831388246f57a7f5de45b1ee5/c0255/Mosquitto_2_Dashboard_14.png 920w", "/en/static/826f256831388246f57a7f5de45b1ee5/c9c44/Mosquitto_2_Dashboard_14.png 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/826f256831388246f57a7f5de45b1ee5/c0255/Mosquitto_2_Dashboard_14.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Return to the `}<strong parentName="p">{`Topic Tree`}</strong>{` you should now see all `}<strong parentName="p">{`retained Topics`}</strong>{` that were published by your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ecdf0eb00cb58e59191da25056f58e4/32ac3/Mosquitto_2_Dashboard_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.39130434782608%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACWElEQVQoz32SXUsbQRSG83u0Rok2MTObnT1ndudzv+JqtJaEXtSLtje9ELQXlvyElpbiVbyw3gh+BLXSnyYGp2Q3lVpKXw6HgTkP5+WdqRWvf6zvXL14cx0PxvHgsuzjpOx/lemPN3au3n34+er9zfbb65fvbmqxVc3njeXlpfbqSnt1pV6fW1qcf7Y0t7A4v1hV/UmvL5TnsmpxnFLaMcZGkQgCBEAfMO9IxThDJNSTUgNyIZUQijEAwADKMT+oGWMIIXEpjgjAlel852bQ1p0IPEq11pxzpXWapkmSpGkqhEBExlgtz3NCiFIqjmME8Fm40W1fbmCvqVkElJDYxkZrpXS3202TJM/zMAxnsNaaUso519oIEfmMFzk5LXBrVfkRUkKstWmaSqmSJDFGW2s55zM4yzJKKQBUQ0GAkWKHa2yTCBbi1LZSnGO1DUphqSlsjPE8DxGttdZoxsDG3uk69FqqtE2NsUqpMAyFENFvCSFmtksYpDJJvs2jrkzSwzW1RSQLkRBSFEWWZp1OhxDS/kOe59UYY9VmKZWxSQBRpPFrBr32zLaUEhEHg8FwODw4OPhYajgcZllWA4AKFkJoJYMAQuV/MmxzBk+vGo3G8fGxc24ymTjnHh4enHN7e3tPYGtNFdiX1O89BqZ1q9kcHR055+7u7iaTyf39vXNud3f3CRzHMQCPlP+tSx9hY0yr1RqNRv+DjTFaKQgwkuxzwXp0BnM+tX1ycuKcq7B/2A7DsHp9QNQhTn8g55RSxGnm+/v7t7e3Z2dnFxcX5+fn4/G43+//ApNwNuXZZzVkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ecdf0eb00cb58e59191da25056f58e4/e4706/Mosquitto_2_Dashboard_15.avif 230w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/d1af7/Mosquitto_2_Dashboard_15.avif 460w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/7f308/Mosquitto_2_Dashboard_15.avif 920w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/92269/Mosquitto_2_Dashboard_15.avif 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ecdf0eb00cb58e59191da25056f58e4/a0b58/Mosquitto_2_Dashboard_15.webp 230w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/bc10c/Mosquitto_2_Dashboard_15.webp 460w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/966d8/Mosquitto_2_Dashboard_15.webp 920w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/c7643/Mosquitto_2_Dashboard_15.webp 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ecdf0eb00cb58e59191da25056f58e4/81c8e/Mosquitto_2_Dashboard_15.png 230w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/08a84/Mosquitto_2_Dashboard_15.png 460w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/c0255/Mosquitto_2_Dashboard_15.png 920w", "/en/static/5ecdf0eb00cb58e59191da25056f58e4/32ac3/Mosquitto_2_Dashboard_15.png 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ecdf0eb00cb58e59191da25056f58e4/c0255/Mosquitto_2_Dashboard_15.png",
              "alt": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker with Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now continue adding all your cameras and use Mosquitto 2 as your central MQTT broker for your smarthome!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      